.error {
  color: red;
  font-weight: bold;
}

$tile-height: 70px;
$tile-width: 70px;
$tile-gap: 10px;

html, body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: row;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.pi-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(30,75,209, 0.2);
  border-right: 1.1em solid rgba(30,75,209, 0.2);
  border-bottom: 1.1em solid rgba(30,75,209, 0.2);
  border-left: 1.1em solid #1e4bd1;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tiles {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
  overflow: visible;

  .tile {
    display: flex;
    position: relative;

    @for $i from 1 through 8 {
      &.cols-#{$i} {
        grid-column: span $i;
        width: $i * $tile-width + ($i - 1) * $tile-gap;
      }

      &.rows-#{$i} {
        grid-row: span $i;
        height: $i * $tile-width + ($i - 1) * $tile-gap;
      }
    }

    &:hover {
      outline: rgba(29, 29, 29, 0.1) solid 4px;
    }

    .teaser {
      font-size: 1.2em;
      padding: 1em;
      background: #f8f9fa;
    }
  }
}

.notifications {
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  right: 2em;
  bottom: 2em;
}

.notification-toast {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 0.8em;
  background-color: #fff;
  max-width: 500px;
  position: relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);

  &+& {
    margin-top: 1em;
  }

  &.success:before {
    background-color: #2BDE3F;
  }

  &.error:before {
    background-color: #e7431a;
  }

  &.info:before {
    background-color: #1D72F3;
  }

  &.warning:before {
    background-color: #FFC007;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  .notification-toast-details {
    flex: 1;

    .notification-toast-title {
      color: #3e3e3e;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .notification-toast-description {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 0;
      color: #878787;
    }
  }

  .notification-toast-close {
    width: 14px;
    cursor: pointer;
    height: 14px;
    fill: #878787;
    margin-left: 1.2em;
    background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642"><path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path></svg>') no-repeat center center;
  }
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--omrs-topnav-height);
  width: 100vw;
  padding: 0 0.5rem;
  backdrop-filter: blur(24px);
  background-color: var(--omrs-color-ink-white);
}

.omrs-unstyled.user-popup {
  display: flex;
  align-items: center;
  color: var(--omrs-color-ink-medium-contrast);
}

.user-menu {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  right: 3px;
  z-index: 2;
  top: 44px;
}

.user-menu-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--omrs-color-ink-lowest-contrast);
  border-radius: 0 0 0.25rem 0.25rem;
  background: var(--omrs-color-bg-high-contrast);
  border-top: none;
}

.hidden {
  display: none;
}
